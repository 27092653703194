<template>
    <a-card>
        <a-page-header
            title='关键词广告管理'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row :gutter="[5,0]">
                <a-col :span="8">
                    <a-form-model-item label="查询周期">
                        <a-range-picker
                            v-model="pickerTime"
                            value-format="YYYY-MM-DD"
                            :disabled-date="disabledDate"
                            :allow-clear="false"
                            @calendarChange="calendarChange"
                            @openChange="openChange"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告计划ID">
                        <a-input
                            v-model="form.adPlanId"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="关键词">
                        <a-input
                            v-model="form.keyword"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="系统开关状态">
                        <a-select
                            v-model="form.sysOptStatus"
                            placeholder="请选择"
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in switchState" :key="item.id">{{ item.label }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告计划状态">
                        <a-select
                            v-model="form.adPlanStatus"
                            placeholder="请选择"
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in planState" :key="item.code">{{ item.msg }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告计划品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告计划车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col span="12">
                    <a-space>
                        <a-button type="primary" @click="batchByKeywordIds('batchEnableByIds')">批量启用</a-button>
                        <a-button type="primary" @click="batchByKeywordIds('batchDisableByIds')">批量暂停</a-button>
                    </a-space>
                </a-col>
                <a-col span="12" class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :row-selection="rowSelection"
            :pagination='false'
            :scroll="{x: 2000}"
            bordered
        >
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <base-button
                    :type="'link'"
                    :title="'详情'"
                    @onClickBtn="openDetailDrawer(record)"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />


        <a-drawer
            placement="right"
            :closable="false"
            :visible="detailVisible"
            :width="720"
            @close="closeDetailDrawer"
        >
            <div class="box">
                <div class="title">广告平台信息</div>
                <div class="row">
                    <div class="name">出价：</div>
                    <div class="detail">{{ dataDetail.bid || '-' }}</div>
                </div>
                <div class="row">
                    <div class="name">出价类型：</div>
                    <div class="detail">{{ dataDetail.bidType == 'CUSTOM' ? '自定义' : dataDetail.bidType == 'WITH_AD' ? '随计划出价' : dataDetail.bidType == 'FEED_TO_SEARCH' ? '搜索快投' : '-' }}</div>
                </div>
                <div class="row">
                    <div class="name">最新关键词排名：</div>
                    <div class="detail">{{ dataDetail.avgRank && dataDetail.avgRank != -1 ? dataDetail.avgRank : '-' }}</div>
                </div>
                <div class="row">
                    <div class="name">关键词ID：</div>
                    <div class="detail">{{ dataDetail.keywordId || '-' }}</div>
                </div>
                <div class="row">
                    <div class="name">词ID：</div>
                    <div class="detail">{{ dataDetail.wordId || '-' }}</div>
                </div>
                <div class="row">
                    <div class="name">广告计划ID：</div>
                    <div class="detail">{{ dataDetail.adPlanId || '-' }}</div>
                </div>
                <div class="row">
                    <div class="name">平台账套ID：</div>
                    <div class="detail">{{ dataDetail.adPlatformAccountId || '-' }}</div>
                </div>
            </div>
            <div class="box">
                <div class="title">皮皮慧投定义信息</div>
                <div class="row">
                    <div class="name">广告计划品牌：</div>
                    <div class="detail">{{ dataDetail.principalName || '-' }}</div>
                </div>
                <div class="row">
                    <div class="name">广告计划车系：</div>
                    <div class="detail">{{ dataDetail.carSeriesName || '-' }}</div>
                </div>
                <div class="row">
                    <div class="name">广告计划场景：</div>
                    <div class="detail">{{ dataDetail.customerSceneDesc || '' }}</div>
                </div>
                <div class="row">
                    <div class="name">广告主：</div>
                    <div class="detail">{{ dataDetail.advertiserName || '-' }}</div>
                </div>
            </div>
        </a-drawer>
    </a-card>
</template>

<script>
import moment from 'moment'

const switchState = [
    { id: 1, label: '启用' },
    { id: 2, label: '暂停' },
    { id: 3, label: '删除' },
]

const renderContent = (data, index) => {
    const obj = {
        children: data,
        attrs: {},
    };
    if (index === 0) {
        obj.attrs.colSpan = 0;
    }
    return obj;
}

export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            colSpan: 12,
            form: {
                adPlanId: this.$route.query.adPlanId,
            },
            pickerTime: [moment(), moment()],
            selectStartDay: '',
            loading: false,
            columns: [
                // begin - 展示合计
                // {
                //     align: 'center',
                //     title: '系统/巨量开关状态',
                //     dataIndex: 'sysOptStatusAndAdOptStatusDesc',
                //     customRender: (text, row, index) => {
                //         if (index > 0) {
                //             return <span>{text || '-'}</span>;
                //         }
                //         return {
                //             children: <span>合计</span>,
                //             attrs: {
                //                 colSpan: 6,
                //             },
                //         }
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '广告组状态',
                //     dataIndex: 'adStatusDesc',
                //     customRender: (text, row, index) => {
                //         return renderContent(text, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '广告组广告组ID',
                //     customRender: (text, row, index) => {
                //         let data = <div>
                //                 <div>{ row.adPlanName || '-' }</div>
                //                 <div class="txt">{row.adPlanId || '-'}</div>
                //             </div>
                //         return renderContent(data, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '操作',
                //     customRender: (text, row, index) => {
                //         let data = <a on-click={() => {this.openDetailDrawer(row.id)}}>详情</a>

                //         return renderContent(data, index)
                //     }
                // },
                // {
                //     align: 'center',
                //     title: '消费（元）',
                //     dataIndex: 'totalCost',
                    // customRender: (text, row, index) => {
                    //     return (text ?? '') !== '' ? text : '-'
                    // }
                // },
                // {
                //     align: 'center',
                //     title: '目标转化数',
                //     dataIndex: 'principalName1',
                //     customRender: (text, row, index) => {
                //         let data = text || '-'

                //         return renderContent(data, index)
                //     }
                // },
                // end - 展示合计

                //begin - 不需要展示合计
                {
                    align: 'center',
                    title: '系统/巨量开关状态',
                    dataIndex: 'sysOptStatusAndAdOptStatusDesc',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告计划状态',
                    dataIndex: 'adPlanStatusDesc',
                    customRender: (text, row, index) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '关键词匹配类型',
                    customRender: (text, row, index) => {
                        return <div>
                                <div>{ row.word	 || '-' }</div>
                                <div class="txt">{row.matchType == 'PHRASE' ? '短语匹配' : row.matchType == 'EXTENSIVE' ? '广泛匹配' : row.matchType == 'PRECISION' ? '精准匹配' : '-'}</div>
                            </div>
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'operation' },
                },
                {
                    align: 'center',
                    title: '消费（元）',
                    dataIndex: 'totalCost',
                    customRender: (text, row, index) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '目标转化数',
                    dataIndex: 'totalConvert',
                    customRender: (text, row, index) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                //end - 不需要展示合计

                {
                    align: 'center',
                    title: '转化单价（元）',
                    dataIndex: 'convertCost',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '展示数',
                    dataIndex: 'totalShow',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击数',
                    dataIndex: 'totalClick',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '展示转化率',
                    dataIndex: 'showConvertRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击率',
                    dataIndex: 'clickRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击转化率',
                    dataIndex: 'clickConvertRate',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text + '%' : '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 50,
                total: 0
            },
            switchState,
            planState: [],
            principalList: [],
            seriesList: [],
            selectedRowKeys: [],
            detailVisible: false,
            dataDetail: {},
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.videoStatus == 3,
                    },
                })
            };
        },
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getAdPlanStatusList()
        this.getDataList()
    },
    methods: {
        disabledDate(current) {
            return current > this.$moment(this.selectStartDay).add(31, 'days') || current < this.$moment(this.selectStartDay).subtract(31, 'days')
        },
        calendarChange(dates, dateStrings) {
            console.log(dates, dateStrings)
            this.selectStartDay = dates[0]
        },
        openChange(status) {
            this.selectStartDay = ''
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res.message}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIdList: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res.message}`)
                }
            })
        },
        getAdPlanStatusList() {
            this.$api.core.JLAdvertManage.getAdPlanStatusList().then((res) => {
                if(res.code == 200) {
                    this.planState = res.data
                } else {
                    this.planState = []
                    this.$message.error(`获取状态列表失败,${res.message}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                adPlanId: this.$route.query.adPlanId,
            }
            this.pickerTime = [moment(), moment()]

            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            
            let params = {
                ..._form,
                startTime: `${moment(this.pickerTime[0]).format('YYYY-MM-DD')} 00:00:00`,
                endTime: `${moment(this.pickerTime[1]).format('YYYY-MM-DD')} 23:59:59`,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.JLAdvertManage.getKeywordList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res.message}`)
                }
            })
        },
        openDetailDrawer(record) {
            this.detailVisible = true
            this.getKeywordDetail(record.id)
        },
        closeDetailDrawer() {
            this.detailVisible = false
            this.dataDetail = {}
        },
        getKeywordDetail(id) {
            this.$api.core.JLAdvertManage.getKeywordDetail(id).then((res) => {
                if(res.code == 200) {
                    this.dataDetail = res.data
                } else {
                    this.dataDetail = {}
                    this.$message.error(`获取详情失败,${res.message}`)
                }
            })
        },
        //批量有效、停用
        batchByKeywordIds(type) {
            if(this.selectedRowKeys.length < 1) {
                this.$message.error('请选择关键词')
            } else {
                let params = {
                    type,
                    data: this.selectedRowKeys
                }
                
                this.$api.core.JLAdvertManage.batchByKeywordIds(params).then((res) => {
                    this.selectedRowKeys = []
                    if(res.code == 200) {
                        this.getDataList()
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error(`操作失败，${res.message}`)
                    }
                })
            }
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.mt-40 {
    margin-top: 40px;
}
.txt {
    color: #ccc;
}
.box {
    .title {
        margin: 20px 0 10px;
        font-size: 16px;
        color: #999;
    }
    .row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .name {
        pad: 5px;
        width: 160px;
        text-align: right;
    }
}
</style>